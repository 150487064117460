<template>
    <div class="h-screen">
        <div class="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center h-full" style="background-color: rgb(239, 243, 248);">
            <div class="surface-card p-4 shadow-2 border-round w-full lg:w-5">
                <div v-show="!success">
                    <p class="font-bold">Escribe tu email para enviarte un enlace para cambiar tu contraseña.</p>
                    <label for="email" class="block text-900 font-medium mb-2">Email</label>
                    <InputText v-model.trim="v$.email.$model" id="email" type="email" class="w-full mb-2" :class="{'p-invalid': v$.email.$error}" required />
                    <div v-for="error of v$.email.$errors" :key="error.$uid" class="mb-4">
                        <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                    </div>

                    <div class="mb-4" v-if="forgotError"><small class="p-error font-bold">{{ forgotError }}</small></div>

                    <Button label="Enviar" icon="pi pi-user" class="w-full mt-4" @click="sendLink" :disabled="canSend"></Button>
                </div>
                <div v-show="success">
                    <div class="text-center mb-2">
                        <div class="text-900 text-3xl font-medium mb-5">Hemos enviado un enlace a tu cuenta de correo. Revisa tu bandeja de spam si no lo encuentras en tu bandeja de entrada.</div>
                        <Button label="Volver" icon="pi pi-arrow-left" @click="goLogin"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '@/service/AuthService';
import ErrorReportService from '@/service/ErrorReportService.js';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import ValidationMessages from '@/validationMessages';

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            email: '',
            working: false,
            forgotError: null,
            error: false,
            success: false
        };
    },
    authService: null,
    errorReportService: null,
    created() {
        this.authService = new AuthService();
        this.errorReportService = new ErrorReportService();
        this.validationMessages = ValidationMessages;
    },
    validations() {
        return {
            email: { required, email },
        };
    },
    computed: {
        canSend() {
            return this.v$.email.$errors.length !== 0 || this.email.length === 0 || this.working;
        }
    },
    methods: {
        sendLink() {
            if (!this.working) {
                this.working = true;
                this.forgotError = null;
                this.success = false;

                this.authService.forgotPassword(this.email)
                    .then(response => {
                        this.working = false;

                        if (response.data.result === 'passwords.sent') {
                            this.success = true;
                        } else {
                            this.forgotError = 'ERROR F-02: No se ha podido enviar el enlace a tu correo.';
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {email: this.email}),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                        this.forgotError = 'ERROR F-01: No se ha podido enviar el enlace a tu correo.';
                    });
            }
        },
        goLogin() {
            this.$router.replace({ name: 'login' });
        }
    }
}
</script>